import React, { useState } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';

const { Option } = Select;

const interests = ['Technology', 'Finance', 'Healthcare', 'Education', 'Marketing', 'Design'];
const industries = ['IT', 'Banking', 'Healthcare', 'Education', 'Marketing', 'Manufacturing'];

const SignupAdditionalInfoModal = ({  }) => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then(values => {
      showModal(values);
      form.resetFields();
    }).catch(info => {
      console.log('Validate Failed:', info);
    });

    hideModal();
  };
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);


  return (
    <>
    <button onClick={showModal}>Enter Additional Info</button>

    <Modal
      visible={modalVisible}
      title="Additional Information"
      onCancel={hideModal}
      footer={[
        <Button key="cancel" onClick={hideModal}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="interests"
          label="What topics are you interested in?"
          rules={[{ required: true, message: 'Please select at least one interest' }]}
        >
          <Select mode="multiple" placeholder="Select your interests">
            {interests.map(interest => (
              <Option key={interest} value={interest}>{interest}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="industry"
          label="What industry do you work in?"
          rules={[{ required: true, message: 'Please select your industry' }]}
        >
          <Select placeholder="Select your industry">
            {industries.map(industry => (
              <Option key={industry} value={industry}>{industry}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="jobTitle"
          label="What is your job title?"
          rules={[{ required: true, message: 'Please enter your job title' }]}
        >
          <Input placeholder="Enter your job title" />
        </Form.Item>
      </Form>
    </Modal>
    </>

  );
};

export default SignupAdditionalInfoModal;
