import React from "react";
import RegisterComponent from "./RegisterComponent.js";
import { Link } from "react-router-dom";

const Register = () => {
  return (
    <>
      <RegisterComponent />
      <p>
        Have an account? <Link to="/login">Sign in</Link>
      </p>
    </>
  );
};

export default Register;
