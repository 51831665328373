import React, { useState, useEffect } from "react";
import Comment from "./Comment";

const CommentList = ({
  comments,
  user,
  handleDeleteComment,
  handleAddComment,
  formatTimestamp,
  setComments,
}) => {
  const [visibleComments, setVisibleComments] = useState([]);

  useEffect(() => {
    setVisibleComments(comments);
  }, [comments]);

  return (
    <div className="mb-6">
      <h2 className="text-lg font-medium mb-2 mt-8">Comments</h2>
      {visibleComments.length > 0 ? (
        <div className="h-full">
          {visibleComments.map((comment) => (
            <Comment
              setComments={setComments}
              key={comment.id}
              comment={comment}
              user={user}
              handleDeleteComment={handleDeleteComment}
              handleAddComment={handleAddComment}
              formatTimestamp={formatTimestamp}
              topLevel={true}
            />
          ))}
        </div>
      ) : (
        <p className="text-gray-500">No comments yet.</p>
      )}
    </div>
  );
};

export default CommentList;
