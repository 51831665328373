import React, { useState, useContext, useEffect } from "react";
import {
  Layout,
  Card,
  Typography,
  Button,
  Switch,
  Divider,
  Form,
  Input,
  Space,
  List,
  message,
} from "antd";
import {
  MailOutlined,
  BellOutlined,
  CreditCardOutlined,
  UserOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { auth } from "../Auth/firebase.js";
import { backend_url } from "../../config.js";

import { AuthContext } from "../Auth/AuthProvider.js";
import {
  getAuth,
  sendEmailVerification,
  updateEmail,
  deleteUser,
  sendPasswordResetEmail,
} from "firebase/auth";

const { Content } = Layout;
const { Title, Text } = Typography;

const UserSettings = () => {
  const { user, userInfo, loading, accessToken } = useContext(AuthContext);

  const [notifications, setNotifications] = useState(true);
  const [newsletterSubscribed, setNewsletterSubscribed] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [showEmail, setShowEmail] = useState(false);

  // TODO
  useEffect(() => {
    if (!loading) {
      setEmails([{ email: user.email, isPrimary: true }]);
      setShowEmail(userInfo.showEmail);
    }
  }, [loading]);

  //TODO
  const resendVerification = async (email) => {
    try {
      if (email === user.email) {
        await sendEmailVerification(user);
      } else {
        // For secondary emails, you'd need to implement a custom solution
        // Firebase doesn't directly support multiple email verifications
        console.log("Verification for secondary emails not implemented");
      }
      message.success(`Verification email sent to ${email}!`);
    } catch (error) {
      message.error(`Failed to send verification email: ${error.message}`);
    }
  };

  // TODO
  const addNewEmail = async () => {
    if (newEmail && !emails.some((e) => e.email === newEmail)) {
      try {
        setEmails([...emails, { email: newEmail, isPrimary: false }]);
        setNewEmail("");
        message.success("New email added successfully!");
      } catch (error) {
        message.error(`Failed to add new email: ${error.message}`);
      }
    }
  };

  // TODO
  const removeEmail = async (emailToRemove) => {
    try {
      setEmails(emails.filter((e) => e.email !== emailToRemove));
      message.success("Email removed successfully!");
    } catch (error) {
      message.error(`Failed to remove email: ${error.message}`);
    }
  };

  const handleSendPasswordResetEmail = async () => {
    const email = user.email;
    try {
      await sendPasswordResetEmail(auth, email);
      message.success("Password reset email sent successfully!");
    } catch (error) {
      message.error(`Failed to send password reset email: ${error.message}`);
    }
  };

  const handleEmailVisibility = async () => {
    try {
      const profile_update = {showEmail: !showEmail};
      userInfo.showEmail = !showEmail;
      fetch(backend_url + "/update/profile?uid=" + user.uid, {
        method: "POST",
        body: JSON.stringify(profile_update),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer: " + accessToken,
        },
      })
      setShowEmail(!showEmail);
    } catch (error) {
      message.error(`Failed to update email visibility: ${error.message}`);
    }
  }

  return (
    <div className="flex flex-wrap">
      <Layout className="min-h-screen bg-gray-100">
        <Content className="p-6">
          <div className="max-w-4xl mx-auto">
            <Title level={2} className="mb-6">
              User Settings
            </Title>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <Card
                title={
                  <Space>
                    <MailOutlined className="text-blue-500" />
                    <span>Email Settings</span>
                  </Space>
                }
                className="shadow-md"
              >

                <Switch
                  className="my-2"
                  checkedChildren="Email Address Visible to Public"
                  unCheckedChildren="Email Address Hidden"
                  checked={showEmail}
                  onChange={() => handleEmailVisibility()}
                />

                <Divider />

                <List
                  itemLayout="horizontal"
                  dataSource={emails}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <Button
                          key="verify"
                          type="link"
                          onClick={() => resendVerification(item.email)}
                          disabled={user.emailVerified && item.isPrimary}
                        >
                          {user.emailVerified && item.isPrimary
                            ? "Verified"
                            : "Verify"}
                        </Button>,
                        !item.isPrimary && (
                          <Button
                            key="delete"
                            type="link"
                            danger
                            onClick={() => removeEmail(item.email)}
                          >
                            <DeleteOutlined />
                          </Button>
                        ),
                      ]}
                    >
                      <List.Item.Meta
                        title={
                          item.isPrimary
                            ? "Primary Email"
                            : `Secondary Email ${index}`
                        }
                        description={item.email}
                      />
                    </List.Item>
                  )}
                />
                <Divider />
                <Form layout="inline" onFinish={addNewEmail}>
                  <Form.Item>
                    <Input
                      placeholder="Add new email"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<PlusOutlined />}
                    >
                      Add Email
                    </Button>
                  </Form.Item>
                </Form>
              </Card>

              <Card
                title={
                  <Space>
                    <BellOutlined className="text-blue-500" />
                    <span>Email Notifications</span>
                  </Space>
                }
                className="shadow-md"
              >
                <Space direction="vertical" className="w-full">
                  <div className="flex justify-between items-center">
                    <Text>Email Digest</Text>
                    <Switch
                      checked={notifications}
                      onChange={setNotifications}
                    />
                  </div>

                  <div className="flex justify-between items-center">
                    <Text>Answers to Questions</Text>
                    <Switch
                      checked={notifications}
                      onChange={setNotifications}
                    />
                  </div>

                  <div className="flex justify-between items-center">
                    <Text>Product Updates</Text>
                    <Switch
                      checked={notifications}
                      onChange={setNotifications}
                    />
                  </div>
                </Space>
              </Card>

              <Card
                title={
                  <Space>
                    <BellOutlined className="text-blue-500" />
                    <span>Online Notifications</span>
                  </Space>
                }
                className="shadow-md"
              >
                <Space direction="vertical" className="w-full">
                  <div className="flex justify-between items-center">
                    <Text>Likes</Text>
                    <Switch
                      checked={notifications}
                      onChange={setNotifications}
                    />
                  </div>

                  <div className="flex justify-between items-center">
                    <Text>Answers to Questions</Text>
                    <Switch
                      checked={notifications}
                      onChange={setNotifications}
                    />
                  </div>
                </Space>
              </Card>

              <Card
                title={
                  <Space>
                    <UserOutlined className="text-blue-500" />
                    <span>Account Settings</span>
                  </Space>
                }
                className="shadow-md"
              >
                <Space direction="vertical" className="w-full">
                  <div className="flex justify-between items-center">
                    <Text>Reset Password</Text>
                    <Button
                      type="primary"
                      onClick={handleSendPasswordResetEmail}
                      icon={<MailOutlined />}
                    >
                      Send Reset Email
                    </Button>
                  </div>
                </Space>
              </Card>
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default UserSettings;
