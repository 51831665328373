import React, { useState } from "react";
import { Link } from "react-router-dom";
import LoginComponent from "./LoginComponent.js";
import PasswordReset from "./PasswordReset.js";
import { motion } from "framer-motion";

const Login = () => {
  const [isPasswordResetOpen, setPasswordResetOpen] = useState(false);
  const togglePasswordReset = () => setPasswordResetOpen(!isPasswordResetOpen);

  return (
    <>
      <LoginComponent />
      <p>
        Don't have an account? <Link to="/register">Create one</Link>
      </p>

      <p
        onClick={togglePasswordReset}
        className="text-gray-600 cursor-pointer hover:underline text-center"
      >
        Forgot your password?
      </p>

      {isPasswordResetOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={togglePasswordReset}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.div
            className="bg-white rounded-lg p-8 shadow-lg"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
            onClick={(e) => e.stopPropagation()}
          >
            <PasswordReset />
          </motion.div>
        </motion.div>
      )}
    </>
  );
};
export default Login;
