import React, { useState } from "react";
import { backend_url } from "../../../config";

const CommentForm = ({
  handleAddComment,
  parentCommentId,
  user,
  onCancel,
  topLevel,
}) => {
  const [content, setContent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    await handleAddComment(content, parentCommentId);
    setContent("");
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={onSubmit} className="mb-4 flex gap-2">
      <img
        src={user?.avatar || backend_url + "/default_profile_pic2.jpeg"}
        alt="User Avatar"
        className="w-10 h-10 rounded-full"
      />
      <div className="flex-1">
        <input
          value={content}
          onChange={(e) => setContent(e.target.value)}
          name="comment"
          className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
          placeholder="Write your comment here..."
          required
          aria-label="Comment"
        />
        <div className="flex gap-x-2 items-center">
          <button
            type="submit"
            disabled={isSubmitting || content.trim() === ""}
            className={`px-4 py-2 rounded-lg bg-indigo-600 text-white text-sm hover:bg-indigo-700 transition duration-200 uppercase ${
              isSubmitting ? "opacity-50" : ""
            }`}
          >
            {isSubmitting ? "Posting..." : "Comment"}
          </button>
          {!topLevel && (
            <button
              onClick={onCancel}
              type="button"
              className="text-gray-800 text-sm ml-2 hover:underline"
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default CommentForm;
