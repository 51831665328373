import React, { useState } from "react";
import { getAuth, signOut } from "firebase/auth";

const Logout = () => {
  const auth = getAuth();
  signOut(auth);

  return (
    <>
      <main className="logout">
        <h1 className="loginTitle">You are logged out</h1>
      </main>
    </>
  );
};
export default Logout;
