import InfiniteScroll from "react-infinite-scroll-component";
import React, { useState, useEffect } from "react";
import axios from "axios";
import QuestionItem from "./QuestionItem.js";
import { backend_url } from "../../config.js";

export default function PostsContainer({ category }) {
  const [items, setItems] = useState([]);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [page, setPage] = useState(1);
  useEffect(() => {
    axios
      .get(backend_url + "/get/questions?page=0&category=" + category)
      .then((res) => {
        if (res.data.length == 0) {
          setHasMorePosts(false);
        } else {
          setHasMorePosts(true);
        }

        setItems(res.data);
      })
      .catch((err) => console.log(err));
  }, [category]);

  function fetchData() {
    fetch(
      backend_url + "/get/questions?page=" + page + "&category=" + category,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.length == 0) {
          setHasMorePosts(false);
        } else {
          setHasMorePosts(true);
        }
        // Data structure = posts collection from DB
        setPage(page + 1);
        setItems((items) => [...items, ...data]);
      })
      .catch((err) => console.error(err));
  }

  function refresh() {
    axios
      .get(backend_url + "/get/questions?page=0&category=" + category)
      .then((res) => {
        setItems(res.data);
      })
      .catch((err) => console.log(err));
  }

  const handleDeletePost = (postId) => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== postId));
  };

  return (
    <InfiniteScroll
      dataLength={items.length}
      next={fetchData}
      hasMore={hasMorePosts}
      loader={<h4>Loading...</h4>}
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>That's all!</b>
        </p>
      }
      // below props only if you need pull down functionality
      refreshFunction={refresh}
      pullDownToRefresh
      pullDownToRefreshThreshold={50}
      pullDownToRefreshContent={
        <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
      }
      releaseToRefreshContent={
        <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
      }
    >
      <div className="w-full h-full mt-1 sm:mt-4 flex flex-col">
        {items?.map((item, index) => (
          <div className="p-4 bg-gray-100" key={index}>
            <QuestionItem
              key={item.id}
              post={item.post}
              postCreator={item.postCreator}
              answerers={item.answerers}
              add_reply={true}
              onDelete={handleDeletePost}
            />
          </div>
        ))}
      </div>
    </InfiniteScroll>
  );
}
