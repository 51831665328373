import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { AuthContext } from "../Auth/AuthProvider.js";
import { backend_url } from "../../config.js";
import { likeIconOutline, likeFill } from "../SvgIcons.jsx";
import Tooltip from "@mui/material/Tooltip";
import { AtSign } from "lucide-react";

const TopAnswer = ({ answer_id, question_id }) => {
  const { user, accessToken } = useContext(AuthContext);
  const [answer, setAnswer] = useState(null);
  const [answerCreator, setAnswerCreator] = useState(null);
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);

  // Get the answer
  useEffect(() => {
    fetch(backend_url + "/api/postfromid?post_id=" + answer_id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAnswer(data);
        setLikeCount(data?.likers?.length || 0);
      })
      .catch((e) => {
        console.error("Did not retrieve post info", answer_id, e);
      });
  }, [answer_id]);

  // Get the answer creator
  useEffect(() => {
    if (answer) {
      fetch(backend_url + "/api/userfromid?user_id=" + answer.uid, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setAnswerCreator(data);
        })
        .catch((e) => {
          console.error("Did not retrieve answer creator info", answer.uid, e);
        });

      if (user && answer?.likers && answer.likers.includes(user.uid)) {
        setLiked(true);
      }
    }
  }, [answer, user]);

  const handleLike = async () => {
    try {
      const endpoint = liked
        ? "/update/post/remove_like?post_id=" + answer_id
        : "/update/post/add_like?post_id=" + answer_id;

      await fetch(backend_url + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer: " + accessToken,
        },
      });

      setLiked(!liked);
      setLikeCount(liked ? likeCount - 1 : likeCount + 1);
    } catch (e) {
      console.error("Error updating like", e);
    }
  };

  return (
    <div className="pt-3 pl-2 m-2">
      <div className="flex items-start space-x-3">
        <Link to={`/profile/${answerCreator?.link}`}>
          <img
            // src={backend_url + "/default_profile_pic2.jpeg"}
            src={
              answerCreator?.profilePicture
                ? answerCreator.profilePicture
                : backend_url + "/default_profile_pic2.jpeg"
            }
            className="w-8 h-8 rounded-full object-cover"
            alt="avatar"
          />
        </Link>
        <div className="flex-grow">
          <div className="flex flex-col">
            <div className="flex items-center space-x-1">
              <Link
                to={`/profile/${answerCreator?.link}`}
                className="text-gray-900 font-medium truncate hover:underline"
              >
                {answerCreator?.firstname} {answerCreator?.lastname}
              </Link>
              <AtSign size={16} className="text-gray-400" />
              <span className="text-gray-500 text-sm truncate">
                {answerCreator?.email}
              </span>
            </div>
            <span className="text-xs text-gray-500">Says</span>
          </div>
          <p className="text-sm mt-1">{answer?.content}</p>
          <div className="flex items-center mt-2">
            <Tooltip title={liked ? "Unlike Reply" : "Like Reply"}>
              <button onClick={handleLike} className="mr-2">
                {liked ? likeFill : likeIconOutline}
              </button>
            </Tooltip>
            <span className="text-sm text-gray-600">{likeCount} likes</span>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Link
          to={`/post/${question_id}`}
          className="text-blue-600 hover:text-blue-800 text-sm font-medium"
        >
          See More Answers...
        </Link>
      </div>
    </div>
  );
};

export default TopAnswer;
