import { formatDistanceToNow } from "date-fns";

export const normalizeTimestamp = (timestamp) => {
  if ( // While old post, firebase labels it as _seconds, _nanoseconds
    timestamp._seconds !== undefined &&
    timestamp._nanoseconds !== undefined
  ) {
    return {
      seconds: timestamp._seconds,
      nanoseconds: timestamp._nanoseconds,
    };
  }

  // Firebase for New Post labels it as seconds/nanoseconds
  return { seconds: timestamp.seconds, nanoseconds: timestamp.nanoseconds };
};

export const formatTimestamp = (seconds, nanoseconds) => {
  if (!seconds) {
    return "Invalid time";
  }

  const date = new Date(seconds * 1000 + nanoseconds / 1000000);
  return formatDistanceToNow(date, { addSuffix: true });
};
