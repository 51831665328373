import {
  HiArrowSmRight,
  HiChartPie,
  HiInbox,
  HiShoppingBag,
  HiTable,
  HiUser,
  HiViewBoards,
} from "react-icons/hi";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
  MenuItemStyles,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
// import '../components.css';
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import React from "react";
import { Switch } from "./Switch.tsx";
import { Diamond } from "./icons/Diamond.tsx";
import { Typography } from "./Typography.tsx";
import { useNavigate } from "react-router-dom";
import { useCategory } from "../../utils/CategoryContext.js";
import categories_react_select from "shared/categories/categories_transformer";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#F3F4F6",
      color: "#607489",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#0b2948",
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export default function Leftsidebar() {
  const navigate = useNavigate();
  const { category, setCategory } = useCategory();
  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(false);
  const [hasImage, setHasImage] = React.useState(false);
  const [theme, setTheme] = React.useState("light");

  // handle on theme change event
  const handleThemeChange = (e) => {
    setTheme(e.target.checked ? "dark" : "light");
  };

  // handle on image change event
  const handleImageChange = (e) => {
    setHasImage(e.target.checked);
  };

  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      [`&.active`]: {
        backgroundColor: "#13395e",
        color: "#b6c8d9",
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <div className="flex h-full mt-1 sm:mt-4 ">
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
        // rtl={rtl}
        breakPoint="md"
        backgroundColor={hexToRgba(
          themes[theme].sidebar.backgroundColor,
          hasImage ? 0.9 : 1
        )}
        rootStyles={{
          color: themes[theme].sidebar.color,
          borderRight: "none",
        }}
      >
        <div className="h-full flex flex-col">
          <div style={{ flex: 1, marginBottom: "32px" }}>
            <div style={{ padding: "0 24px", marginBottom: "8px" }}>
              <Typography
                variant="body2"
                fontWeight={600}
                style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: "0.5px" }}
              >
                Feed Display Setting
              </Typography>
            </div>

            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                icon={<HourglassBottomIcon />}
                onClick={() => {
                  setCategory("New");
                  navigate("/");
                }}
              >
                New
              </MenuItem>
              <MenuItem
                icon={<Diamond />}
                onClick={() => {
                  setCategory("Top");
                  navigate("/");
                }}
              >
                Top
              </MenuItem>
              {/* <MenuItem
                disabled
                icon={<Global />}
                // suffix={
                //   <Badge variant="danger" shape="circle">
                //     6
                //   </Badge>
                // }
              >
                Near Me
              </MenuItem>
              <MenuItem
                disabled
                icon={<Global />}
                // suffix={
                //   <Badge variant="danger" shape="circle">
                //     6
                //   </Badge>
                // }
              >
                Following
              </MenuItem> */}
            </Menu>

            <div
              style={{
                padding: "0 24px",
                marginBottom: "8px",
                marginTop: "32px",
              }}
            >
              <Typography
                variant="body2"
                fontWeight={600}
                style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: "0.5px" }}
              >
                Topics
              </Typography>
            </div>

            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                active={true}
                onClick={() => {
                  setCategory("All");
                  navigate("/");
                }}
              >
                All
              </MenuItem>

              {categories_react_select.map((category) => (
                <MenuItem
                  key={category.label}
                  active={true}
                  onClick={() => {
                    setCategory(category.label);
                  }}
                >
                  {category.label}
                </MenuItem>
              ))}
            </Menu>

            {/* Support  */}
            <div
              style={{
                padding: "0 24px",
                marginBottom: "8px",
                marginTop: "32px",
              }}
            >
              <Typography
                variant="body2"
                fontWeight={600}
                style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: "0.5px" }}
              >
                Support
              </Typography>
            </div>
            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                active={true}
                onClick={() => {
                  setCategory("ContactUs");
                  navigate("/");
                }}
              >
                Contact Us
              </MenuItem>
            </Menu>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}
