import React, { useState, useEffect, useContext } from "react";
import { FaEnvelope, FaPhone, FaLinkedin } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { backend_url } from "../../config.js";
import { AuthContext } from "../Auth/AuthProvider";
import { Button, Input, Modal, Switch, Popconfirm } from "antd";
import UpdateExperience from "./UpdateExperience.js";
import UpdateEducation from "./UpdateEducation.js";
import DragDrop from "./ProfilePictureUploader.js";

const UserProfile = () => {
  const { user, loading, accessToken } = useContext(AuthContext);
  const { user: user_link } = useParams();

  const [profile, setProfile] = useState(true);
  const [isUser, setIsUser] = useState(false);
  const [profileEditing, setProfileEditing] = useState(false);
  const [editedRole, setEditedRole] = useState(false);
  const [editedSummary, setEditedSummary] = useState(false);
  const [editedLinkedin, setEditedLinkedin] = useState("");
  const [skills, setSkills] = useState([]);

  const linkedin_domain = "https://linkedin.com/in/";

  useEffect(() => {
    fetch(backend_url + "/get/profilefromlink?link=" + user_link, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setProfile(data);
        setEditedRole(data.role);
        setEditedSummary(data.summary);
        setEditedLinkedin(data.linkedin);
        setSkills(data.skills);

        return data;
      })
      .catch((error) => {
        console.error("Error retrieving post or children", user_link, error);
      });
  }, [user_link]);

  useEffect(() => {
    if (profile?.uid && user?.uid && profile?.uid == user?.uid) {
      setIsUser(true);
    }
  }, [user, profile]);

  const submitProfileEdits = () => {
    let profile_update = {};
    if (editedSummary != profile.summary) {
      profile_update.summary = editedSummary;
    }
    if (editedRole != profile.role) {
      profile_update.role = editedRole;
    }
    if (editedLinkedin != profile.linkedin) {
      profile_update.linkedin = editedLinkedin;
    }
    fetch(backend_url + "/update/profile?uid=" + user.uid, {
      method: "POST",
      body: JSON.stringify(profile_update),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer: " + accessToken,
      },
    }).catch((error) => {
      console.error("Error submitting profile edit", user_link, error);
    });
    setProfileEditing(false);
    setProfile({ ...profile, ...profile_update });
  };

  //TODO(): Add confirmation of losing changes on dialog when closing modal
  // const showConfirm = () => {
  //     Modal.confirm({
  //       title: 'Are you sure you want to close this modal?',
  //       content: 'Any unsaved changes will be lost.',
  //       onOk() {
  //       },
  //       onCancel() {
  //         // Do nothing, just close the confirmation dialog
  //       },
  //     });
  //   };

  const cancelProfileEdits = () => {
    // showConfirm();
    setSkills(profile.skills);
    setEditedSummary(profile.summary);
    setEditedRole(profile.role);
    setProfileEditing(false);
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
      <div className="bg-gray-100 min-h-screen p-4 sm:p-6 grow">
        <div className="w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
          <div
            className="bg-cover bg-center h-32 sm:h-40"
            style={{ backgroundImage: `url('/path/to/cover.jpg')` }}
          ></div>

          {/* Profile */}
          <div className="flex flex-col items-center p-4 sm:p-6">
            {/* Profile Picture */}
            <img
              src={profile?.profilePicture || "/path/to/default-avatar.jpg"}
              alt="User Avatar"
              className="w-24 h-24 sm:w-28 sm:h-28 rounded-full border-4 border-white shadow-lg"
            />
            {isUser && <DragDrop />}
            {/* Profile Information */}
            <div className="mt-3 flex flex-col w-full mx-auto relative">
              <div className="flex flex-col items-center">
                <h2 className="text-xl sm:text-2xl font-semibold text-gray-800">
                  {profile?.firstname} {profile?.lastname}
                </h2>

                <p className="text-sm sm:text-base text-gray-600">
                  {editedRole}
                </p>

                <p className="flex items-center text-gray-700">
                  <FaEnvelope className="mr-2 text-blue-600" />
                  {profile?.email}
                </p>

                {/* Profile Summary */}
                <div className="profile-summary-section w-full max-w-2xl">
                  {editedSummary && (
                    <p className="break-words text-center mb-4 whitespace-pre-wrap overflow-y-auto max-h-80 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                      {editedSummary}
                    </p>
                  )}
                  {!editedSummary && isUser && (
                    <p className="break-words text-center text-gray-500	">
                      Add summary here
                    </p>
                  )}
                </div>
              </div>

              {/* Edit Profile */}
              {isUser && (
                <div>
                  <Button
                    className="absolute top-0 right-0"
                    onClick={() => setProfileEditing(true)}
                  >
                    Edit
                  </Button>

                  <Modal
                    title="Edit Your Profile"
                    open={profileEditing}
                    onOk={() => {
                      submitProfileEdits();
                    }}
                    onCancel={() => cancelProfileEdits()}
                  >
                    <h3 className="text-base sm:text-sm font-semibold text-gray-800 mb-2">
                      Role
                    </h3>
                    {/* Remember to change character limit on server and registration well */}
                    <Input.TextArea
                      value={editedRole}
                      onChange={(e) => setEditedRole(e.target.value)}
                      maxLength={50}
                      count={{
                        show: true,
                        max: 50,
                      }}
                      rows={2}
                    />

                    <h3 className="text-base sm:text-sm font-semibold text-gray-800 mb-2">
                      Summary
                    </h3>
                    {/* Remember to change character limit on server as well */}
                    <Input.TextArea
                      value={editedSummary}
                      onChange={(e) => setEditedSummary(e.target.value)}
                      maxLength={500}
                      count={{
                        show: true,
                        max: 500,
                      }}
                      rows={4}
                    />

                    <h3 className="text-base sm:text-sm font-semibold text-gray-800 mb-2">
                      Linkedin
                    </h3>
                    {/* TODO(): Add message when reaching character limit */}
                    {/* Remember to change character limit on server as well */}
                    <Input
                      addonBefore={linkedin_domain}
                      value={editedLinkedin}
                      onChange={(e) => setEditedLinkedin(e.target.value)}
                      maxLength={50}
                      rows={1}
                      count={{
                        show: true,
                        max: 50,
                      }}
                    />
                  </Modal>
                </div>
              )}
            </div>
            {/* <div className="flex mt-4 space-x-2">
                            <button className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm hover:bg-blue-700 shadow">
                                Connect
                            </button>
                            <button className="bg-gray-300 text-gray-800 px-3 py-1 rounded-full text-sm hover:bg-gray-400 shadow">
                                Message
                            </button>
                        </div> */}
            <div className="p-4 sm:p-6 space-y-2 text-sm sm:text-base flex flex-row">
              <p className="flex items-center text-gray-700">
                {profile?.linkedin && (
                  <a
                    href={linkedin_domain + profile.linkedin}
                    target="_blank"
                    className="hover:underline"
                  >
                    <FaLinkedin className="mr-2 text-blue-700 fa-5x" />
                  </a>
                )}
              </p>
            </div>
          </div>

          {/* EXPERIENCE */}
          <div className="p-4 sm:p-6 bg-gray-50">
            <div className="flex flex-row relative">
              <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3">
                Experience
              </h3>

              {isUser && (
                <UpdateExperience
                  profile={profile}
                  setProfile={setProfile}
                  index={undefined}
                />
              )}
            </div>
            <div className="space-y-2">
              {profile?.experience?.map((exp, index) => (
                <div key={index} className="py-2 relative">
                  <h4 className="text-base sm:text-lg font-bold text-gray-800">
                    {exp.jobTitle}
                  </h4>
                  <p className="text-sm text-gray-600">{exp.company}</p>
                  <p className="text-xs text-gray-500">
                    {exp.startDate} to {exp.endDate}
                  </p>
                  <p className="text-xs text-gray-500">{exp.description}</p>
                  {isUser && (
                    <UpdateExperience
                      profile={profile}
                      setProfile={setProfile}
                      index={index}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* SKILLS */}
          {/* <div className="p-4 sm:p-6">
                        <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3">Skills</h3>
                        <div className="flex flex-wrap">
                            {profile?.skills?.map((skill, index) => (
                                <span
                                    key={index}
                                    className="bg-gray-100 text-gray-700 px-2 py-1 rounded-full text-xs sm:text-sm font-medium m-1 shadow-sm"
                                >
                                    {skill}
                                </span>
                            ))}
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
