import { useState, useCallback, useEffect } from "react";
import { getAuth } from "firebase/auth";
import axios from "axios";

export default function ContactUs() {
  const [inputValues, setInputValues] = useState({
    reason: "",
    name: "",
    from: "",
    text: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const onChangeHandler = useCallback(({ name, value }) => {
    try {
      setInputValues((currentValues) => ({
        ...currentValues,
        [name]: value,
      }));
    } catch (error) {
      console.error(error);
    }
  }, []);

  const submitHandler = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        if (
          !inputValues.reason ||
          !inputValues.name ||
          !inputValues.from ||
          !inputValues.text
        ) {
          setErrorStatus(true);
          setErrorMessage("All the fields are required!");
          return;
        }

        const data = await axios.put("http://localhost:4000/api/sendEmail", {
          reason: inputValues.reason,
          name: inputValues.name,
          from: inputValues.from,
          text: inputValues.text,
        });

        if (data) {
          // email was successfully sent

          setErrorStatus(false);
          setSuccessMessage(true);
        }

        setInputValues({ reason: "", name: "", from: "", text: "" });
        setSuccessMessage(true);
        setErrorMessage(false);
      } catch (error) {
        setErrorMessage(
          error.message || "An error occurred. Please try again."
        );
        setErrorStatus(true);
        setSuccessMessage(null);
        console.error(error);
      }
    },
    [inputValues]
  );

  // Checks firebase token for email when page loads. If it exist then updates
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      setInputValues((currentValues) => ({
        ...currentValues,
        from: user.email,
      }));
    }
  }, []);

  return (
    <body className="bg-gray-100 m-5 ">
      <div className="flex w-full min-h-screen justify-center items-center ">
        <div className="flex flex-col  space-y-6 md:flex-row md:space-x-6 md:space-y-0 bg-customPurple marker:w-full max-w-4xl p-8 sm:p-12 rounded-xl shadow-lg text-white">
          {successMessage ? (
            <p>
              Your message has been sent! A member of the team will get back to
              you in a bit! Thank you
            </p>
          ) : (
            <>
              {/* Left of Container */}
              <div className="flex flex-col space-y-8 justify-between md:w-1/2">
                <div>
                  <h1 className="font-bold text-4xl tracking-wide">
                    Your Voice Matters!
                  </h1>
                  <p className="pt-2 text-white text-md">
                    Got questions, ideas, or just want to say hey? We’re all
                    ears – hit us up and let’s chat!
                  </p>
                </div>

                {/* Company Information */}
                <div className="flex flex-col space-y-6">
                  <div className="inline-flex space-x-2 items-center">
                    <div className="text-xl">
                      <svg
                        className="w-[35px] h-[35px] text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        width="800px"
                        height="800px"
                        viewBox="0 0 24 24"
                        fill="white"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3.75 5.25L3 6V18L3.75 18.75H20.25L21 18V6L20.25 5.25H3.75ZM4.5 7.6955V17.25H19.5V7.69525L11.9999 14.5136L4.5 7.6955ZM18.3099 6.75H5.68986L11.9999 12.4864L18.3099 6.75Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <span className="text-lg">support@abstruse1.com</span>
                  </div>
                </div>

                {/* Social Media Icons */}
                <div className="flex space-x-4 text-lg flex-col space-y-4">
                  <p className="text-xl underline">Follow us on Social Media</p>
                  <a
                    href="https://www.linkedin.com/company/abstruse1/"
                    className="cursor-pointer"
                  >
                    <svg
                      className="h-[30px] w-[30px]"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="white"
                      width="800px"
                      height="800px"
                      viewBox="0 0 1920 1920"
                    >
                      <path
                        d="M1168 601.321v74.955c72.312-44.925 155.796-71.11 282.643-71.11 412.852 0 465.705 308.588 465.705 577.417v733.213L1438.991 1920v-701.261c0-117.718-42.162-140.06-120.12-140.06-74.114 0-120.12 23.423-120.12 140.06V1920l-483.604-4.204V601.32H1168Zm-687.52-.792v1318.918H0V600.53h480.48Zm-120.12 120.12H120.12v1078.678h240.24V720.65Zm687.52.792H835.267v1075.316l243.364 2.162v-580.18c0-226.427 150.51-260.18 240.24-260.18 109.55 0 240.24 45.165 240.24 260.18v580.18l237.117-2.162v-614.174c0-333.334-93.573-457.298-345.585-457.298-151.472 0-217.057 44.925-281.322 98.98l-16.696 14.173H1047.88V721.441ZM240.24 0c132.493 0 240.24 107.748 240.24 240.24 0 132.493-107.747 240.24-240.24 240.24C107.748 480.48 0 372.733 0 240.24 0 107.748 107.748 0 240.24 0Zm0 120.12c-66.186 0-120.12 53.934-120.12 120.12s53.934 120.12 120.12 120.12 120.12-53.934 120.12-120.12-53.934-120.12-120.12-120.12Z"
                        fillRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>

              {/* Right of Container */}
              <div className="md:w-1/2">
                <div className="bg-white rounded-xl shadow-lg p-8 text-gray-600 md:w-full">
                  {errorStatus ? (
                    <p className="text-red-600 text-md text-center">
                      {errorMessage}
                    </p>
                  ) : null}
                  <form className="flex flex-col space-y-4">
                    <div className="flex flex-col space-y-4">
                      <label htmlFor="contactReason" className="text-md">
                        Reason for Contacting Us
                      </label>
                      <select
                        name="reason"
                        value={inputValues.reason}
                        onChange={(event) => {
                          onChangeHandler(event.target);
                        }}
                        id="contactReason"
                        className="ring-1 ring-gray-300 w-full rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-indigo-300"
                      >
                        <option value="" disabled>
                          Select a reason...
                        </option>
                        <option value="support">Customer Support</option>
                        <option value="feedback">Product Feedback</option>
                        <option value="inquiry">General Inquiry</option>
                        <option value="partnership">Partnership Request</option>
                        <option value="other">Other</option>
                      </select>
                    </div>

                    <div>
                      <label className="text-md">Your Name</label>
                      <input
                        name="name"
                        onChange={(event) => {
                          onChangeHandler(event.target);
                        }}
                        value={inputValues.name}
                        placeholder="Your Name"
                        className="ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-indigo-300"
                      />
                    </div>

                    <div>
                      <label className="text-md">Your Email</label>
                      <input
                        name="from"
                        onChange={(event) => {
                          onChangeHandler(event.target);
                        }}
                        disabled
                        value={inputValues.from}
                        placeholder="Your Email"
                        className="ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-indigo-300"
                      />
                    </div>

                    <div>
                      <label className="text-md">Your Message</label>
                      <textarea
                        value={inputValues.text}
                        name="text"
                        onChange={(event) => {
                          onChangeHandler(event.target);
                        }}
                        rows="4"
                        placeholder="Your Message"
                        className="ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-indigo-300"
                      />
                    </div>

                    <button
                      onClick={(event) => submitHandler(event)}
                      className="inline-block self-end bg-customPurple text-white font-bold rounded-lg px-6 py-2 uppercase text-sm"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </body>
  );
}
