import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../components.scss';
import { signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import {auth} from "./firebase.js"
import {backend_url} from "../../config.js";

const RegisterComponent = () => {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [role, setRole] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch(backend_url + "/auth/register", {
          method: "POST",
          body: JSON.stringify({
            firstname,
            lastname,
            role,
            email,
            password,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
            if (res.status != 200) {
                return res.json().then(data => { 
                    throw data; 
                });
            } else {
                return res.json()
            }
        }).then(() => {
            return signInWithEmailAndPassword(auth, email, password)
        }).then(() => {
            sendEmailVerification(auth.currentUser)
            .then(() => {
                navigate('/')
            })
        }).catch((err) => {
            console.error("Error:", err.message);
            alert(err.message)
        });
    };
    return (
            <main className='register p-30 justify-center items-center'>
                <h1 className='text-2xl font-bold mb-4'>Create an account</h1>
                <form className='registerForm' onSubmit={handleSubmit}>
                    <label htmlFor='firstname'>First Name</label>
                    <input
                        type='text'
                        name='firstname'
                        id='firstname'
                        required
                        onChange={(e) => setFirstname(e.target.value)}
                        className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />

                    <label htmlFor='lastname'>Last Name</label>
                    <input
                        type='text'
                        name='lastname'
                        id='lastname'
                        required
                        onChange={(e) => setLastname(e.target.value)}
                        className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />

                    <label htmlFor='role'>Role at Your Organization</label>
                    <input
                        type='text'
                        name='role'
                        id='role'
                        required
                        onChange={(e) => setRole(e.target.value)}
                        className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />

                    <label htmlFor='email'>Company or University Email Address</label>
                    <input
                        type='text'
                        name='email'
                        id='email'
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />

                    <label htmlFor='password'>Password</label>
                    <input
                        type='password'
                        name='password'
                        id='password'
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />

                    <button className='w-full bg-blue-600 text-white py-3 rounded-lg font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500'>REGISTER</button>
                </form>
            </main>
    );
};

export default RegisterComponent;
