import React, { useState, useEffect } from "react";
import {
  formatTimestamp,
  normalizeTimestamp,
} from "../../utils/formatTimestamp";
import { message } from "antd";
import Modal from "react-modal";
import CommentForm from "./Comments/CommentForm";
import CommentList from "./Comments/CommentList";

// Modal.setAppElement("#root");

const ReplyModal = ({
  isOpen,
  onClose,
  reply,
  creator,
  user,
  accessToken,
  backend_url,
}) => {
 
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen && reply) {
      fetchComments();
    }
  }, [isOpen, reply]);

  const fetchComments = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${backend_url}/reply/comments?reply_id=${reply.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch comments");
      }

      const commentsData = await response.json();

      setComments(commentsData);
    } catch (error) {
      console.error("Error fetching comments:", error);
      alert("Failed to load comments. Please try again.");
    }
    setLoading(false);
  };

  const handleAddComment = async (content, parentCommentId = reply.id) => {
    if (!content.trim()) return;

    if (!user) {
      alert("You must be logged in to add a comment.");
      return;
    }

    try {
      const response = await fetch(`${backend_url}/reply/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          reply_id: reply.id,
          content,
          parent_comment_id: parentCommentId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to add comment");
      }

      const responseData = await response.json();
      console.log(responseData);
      fetchComments();
      message.success("Comment added successfully");
    } catch (error) {
      console.error("Error adding comment:", error);
      alert("Failed to add comment. Please try again.");
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const response = await fetch(
        `${backend_url}/reply/comments/${commentId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete comment");
      }

      fetchComments();
      message.success("Comment deleted successfully");
    } catch (error) {
      console.error("Error deleting comment:", error);
      alert("Failed to delete comment. Please try again.");
    }
  };

  if (!isOpen || !reply) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Reply Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.35)",
          zIndex: 1000,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-45%, -37%)",
          padding: "25px",
          maxHeight: "50vh",
          width: "90%",
          maxWidth: "1200px",
          minHeight: "75%",
        },
      }}
    >
      <button
        onClick={onClose}
        className="text-gray-500 hover:text-gray-700 float-right text-2xl font-bold"
        aria-label="Close Modal"
      >
        &times;
      </button>

      <div className="mb-6">
        <span className="text-sm text-gray-600 mb-4">
          @{creator ? `${creator.firstname} ${creator.lastname}` : "Unknown"} (
          {creator ? creator.role : "N/A"})
        </span>
        <p className="text-xl font-semibold mb-2">{reply.content}</p>
        <span className="text-sm text-gray-500">
          {reply?.timestamp
            ? formatTimestamp(
                normalizeTimestamp(reply.timestamp).seconds,
                normalizeTimestamp(reply.timestamp).nanoseconds
              )
            : "N/A"}
        </span>
      </div>

      <CommentForm
        handleAddComment={handleAddComment}
        parentCommentId={reply.id}
        topLevel={true}
      />

      {loading ? (
        <p className="text-gray-500">Loading comments...</p>
      ) : (
        <CommentList
          setComments={setComments}
          comments={comments}
          user={user}
          handleDeleteComment={handleDeleteComment}
          handleAddComment={handleAddComment}
          formatTimestamp={formatTimestamp}
        />
      )}
    </Modal>
  );
};

export default ReplyModal;
