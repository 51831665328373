import React, { useState } from "react";
import RegisterComponent from "./Auth/RegisterComponent.js";
import { motion } from "framer-motion";
import LoginComponent from "./Auth/LoginComponent.js";
import PasswordResetComponent from "./Auth/PasswordReset.js";

const Landing = () => {
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isPasswordResetOpen, setPasswordResetOpen] = useState(false);

  const toggleSignUp = () => setSignUpOpen(!isSignUpOpen);
  const toggleLogin = () => setLoginOpen(!isLoginOpen);
  const togglePasswordReset = () => setPasswordResetOpen(!isPasswordResetOpen);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      <motion.div
        className="text-center mb-12"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Welcome to Abstruse
        </h1>
        <p className="text-lg text-gray-600">
          Connect with academic and industry professionals, share technical
          ideas, and grow your network.
        </p>
      </motion.div>

      <motion.div
        className="flex space-x-4"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-2">
            <button
              onClick={toggleSignUp}
              className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
            >
              Sign Up
            </button>
            <button
              onClick={toggleLogin}
              className="px-6 py-3 bg-gray-600 text-white font-semibold rounded-lg shadow-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75"
            >
              Log In
            </button>
          </div>
          <p
            onClick={togglePasswordReset}
            className="text-gray-600 cursor-pointer hover:underline text-center"
          >
            Forgot your password?
          </p>
        </div>

        {/* Changed from button to text */}
      </motion.div>

      {isSignUpOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={toggleSignUp}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.div
            className="bg-white rounded-lg p-8 shadow-lg"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
            onClick={(e) => e.stopPropagation()}
          >
            <RegisterComponent />
          </motion.div>
        </motion.div>
      )}

      {isLoginOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={toggleLogin}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.div
            className="bg-white rounded-lg p-8 shadow-lg"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
            onClick={(e) => e.stopPropagation()}
          >
            <LoginComponent />
          </motion.div>
        </motion.div>
      )}

      {isPasswordResetOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={togglePasswordReset}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.div
            className="bg-white rounded-lg p-8 shadow-lg"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
            onClick={(e) => e.stopPropagation()}
          >
            <PasswordResetComponent />
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default Landing;
