import { useEffect, useContext, useState, useCallback } from "react";
import TopAnswer from "./TopAnswer.js";
import UserDetailsComponent from "../Users/UserDetailsComponent.js";
import { AuthContext } from "../Auth/AuthProvider.js";
import { collection, addDoc, Timestamp, setDoc, doc } from "firebase/firestore";
import { db } from "../Auth/firebase.js";
import PostBottom from "./PostBottom.js";
import categories_react_select from "shared/categories/categories_transformer";
import Select from "react-select";

const QuestionItem = ({
  post,
  postCreator,
  answerers,
  onDelete,
  reply_page = false,
}) => {
  const content = post?.content;
  const title = post?.title;
  const question = post?.type == 1;
  const tags = post?.tags;

  const { user } = useContext(AuthContext);

  const [isPostCreator, setIsPostCreator] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const changeEditStatus = () => {
    setIsEdit(!isEdit);
  };

  const [editableTitle, setEditableTitle] = useState(title);
  const [editableContent, setEditableContent] = useState(content);
  const [editableTag, setEditableTag] = useState([]);

  useEffect(() => {
    if (post) {
      setEditableTitle(post?.title);
      setEditableContent(post?.content);

      const formattedTags = post?.tags.map((tag) => ({
        label: tag,
        value: tag,
      }));
      setEditableTag(formattedTags);
    }
  }, [post]);

  const updatePost = useCallback(
    async (event) => {
      try {
        event.preventDefault();

        // creating a new updated Doc
        const updatedDoc = {
          type: 1,
          title: editableTitle,
          content: editableContent,
          uid: user.uid,
          timestamp: Timestamp.now(),
          tags:
            question && isPostCreator
              ? editableTag.map((element) => element.value)
              : post?.tags,
        };

        // Finding the DB entry where I will be updating
        const postRef = doc(db, "posts", post?.id);

        // Updating Doc
        await setDoc(postRef, updatedDoc, { merge: true });
        post.title = editableTitle;
        post.content = editableContent;
        post.tags = isPostCreator ? editableTag : post?.tags;
        console.log("Successfully Updated");

        setIsEdit(false);
      } catch (error) {
        console.error("Error updating post:", error);
      }
    },
    [editableContent, editableTitle, editableTag, question, isPostCreator]
  );

  useEffect(() => {
    if (user && post) {
      setIsPostCreator(postCreator?.uid === user?.uid);
    }
  }, [user, post]);

  return (
    <div className="flex flex-col border rounded-lg shadow-lg bg-white relative border-zinc-300	drop-shadow">
      <UserDetailsComponent postCreator={postCreator} />

      {/* Content */}
      <div className="flex flex-col relative">
        {!isEdit ? (
          <>
            <div
              className="font-sans text-xl pb-1 font-bold px-1"
              data-testid="post-title"
            >
              {title}
            </div>
            <div
              className="whitespace-pre-wrap px-1 break-all"
              data-testid="post-content"
            >
              {content}
            </div>
          </>
        ) : (
          <>
            <form
              className="w-[95%] mx-auto p-3 border border-indigo-600 border-solid rounded-md "
              onSubmit={updatePost}
            >
              <label className="block m-2 text-md font-semibold underline text-gray-700 mb-1">
                Title
              </label>
              <input
                onChange={(event) => setEditableTitle(event.target.value)}
                className="whitespace-pre-wrap w-full py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={editableTitle}
              />
              <label className="block m-2 text-md font-semibold underline text-gray-700 mb-1">
                Content
              </label>
              <textarea
                onChange={(event) => setEditableContent(event.target.value)}
                className="whitespace-pre-wrap w-full py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={editableContent}
              />
              {/* Allowing User to edit the tags IF they are the one who posted the question */}
              {question && isPostCreator ? (
                <Select
                  isMulti
                  value={editableTag}
                  onChange={(tags) => setEditableTag(tags)}
                  options={categories_react_select}
                  placeholder="Select tags..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                  required
                />
              ) : null}
              <div className="flex justify-end">
                <button
                  data-testid="submit-button"
                  type="submit"
                  className="m-2 py-1 px-3 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  Save
                </button>
              </div>
            </form>
          </>
        )}
      </div>
      <PostBottom
        changeEditStatus={changeEditStatus}
        isEdit={isEdit}
        post={post}
        isPostCreator={isPostCreator}
        answerers={answerers}
        commentIconBool={true}
        onDelete={onDelete}
        reply_page={reply_page}
      />
      {/* Reply */}
      {!reply_page && post?.replies && post.replies.length > 0 && (
        <TopAnswer answer_id={post.replies[0]} question_id={post?.id} />
      )}
    </div>
  );
};

export default QuestionItem;
