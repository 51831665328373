import React, { useState, useCallback } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import { backend_url } from "../../config";
import { useMediaQuery, useTheme } from "@mui/material";

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const isWideMobile = useMediaQuery('(min-width: 750px)');
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const handleSearch = useCallback(
    debounce(async (query) => {
      if (query.trim()) {
        try {
          const response = await fetch(
            `${backend_url}/search?q=${encodeURIComponent(query)}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          setSearchResults(data.results);
          setShowResults(true);
        } catch (error) {
          console.error("Error during search:", error);
        }
      }
    }, 300),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    handleSearch(value);
  };

  const handleResultClick = (result) => {
    setShowResults(false);
    if (result.type === "post") {
      navigate(`/post/${result.id}`);
    } else if (result.type === "user") {
      navigate(`/profile/${result.firstname}${result.lastname}`);
    }
  };

  const handleClickAway = () => {
    setShowResults(false);
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: "relative", mx: 2 }}>
        <TextField
          variant="outlined"
          size="small"
          placeholder={isDesktop || isWideMobile ? "Enter Post Title, Content, or User Name" : "Search"}
          value={searchQuery}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: {
              borderRadius: "50px",
              backgroundColor: "white",
            },
          }}
          sx={{
            width: isDesktop || isWideMobile ? "400px" : "150px",
            maxWidth: "100%",
            transition: "width 0.2s ease-in-out",
          }}
        />
        <Paper
          sx={{
            position: "absolute",
            top: "45px",
            width: "100%",
            maxHeight: showResults ? "300px" : "0px",
            overflowY: "auto",
            transition: "max-height 0.3s ease, opacity 0.3s ease",
            zIndex: 10,
            boxShadow: showResults ? "0px 4px 10px rgba(0, 0, 0, 0.1)" : "none",
            opacity: showResults ? 1 : 0,
            visibility: showResults ? "visible" : "hidden",
            backgroundColor: "white",
            borderRadius: "8px",
          }}
        >
          {searchResults.length > 0 ? (
            <List>
              {searchResults.map((result) => (
                <React.Fragment key={result.id}>
                  <ListItem button onClick={() => handleResultClick(result)}>
                    <ListItemText
                      primary={
                        result.type === "post"
                          ? truncateText(result.title || "Untitled Post", 100)
                          : truncateText(
                              `${result.firstname} ${result.lastname}`,
                              50
                            )
                      }
                      secondary={
                        result.type === "post"
                          ? truncateText(result.content, 100)
                          : truncateText(result.role, 100)
                      }
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Box sx={{ padding: 2 }}>
              <Typography variant="body2" color="textSecondary">
                No results found
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
    </ClickAwayListener>
  );
};

export default SearchBar;
