import { useEffect, useContext, useState, useCallback } from "react";
import { commentIcon } from "../SvgIcons.jsx";
import { AuthContext } from "../Auth/AuthProvider.js";
import UserDetailsComponent from "../Users/UserDetailsComponent.js";
import PostBottom from "./PostBottom.js";

import { Timestamp, setDoc, doc } from "firebase/firestore";
import { db } from "../Auth/firebase.js";

const AnswerItem = ({
  post,
  postCreator,
  item,
  onDelete,
  handleReplyClick,
}) => {
  const content = post?.content;
  const question = post?.type == 1;

  const { user } = useContext(AuthContext);
  const [isPostCreator, setIsPostCreator] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [editableContent, setEditableContent] = useState(content);

  const changeEditStatus = () => {
    setIsEdit(!isEdit);
  };

  const updatePost = useCallback(
    async (event) => {
      try {
        event.preventDefault();

        // creating a new updated Doc
        const updatedDoc = {
          content: editableContent,
          uid: user.uid,
          timestamp: Timestamp.now(),
        };
        // Finding the DB entry where I will be updating

        const postRef = doc(db, "posts", post?.id);

        // Updating Doc
        await setDoc(postRef, updatedDoc, { merge: true });

        post.content = editableContent;

        console.log("Successfully Updated");

        setIsEdit(false);
      } catch (error) {
        console.error("Error updating post:", error);
      }
    },
    [editableContent, question, isPostCreator]
  );

  useEffect(() => {
    if (user?.uid && postCreator?.uid) {
      setIsPostCreator(postCreator?.uid === user?.uid);
    }
  }, [user, postCreator]);

  return (
    <div className="flex flex-col border rounded bg-white relative border-zinc-300	drop-shadow	">
      <UserDetailsComponent postCreator={postCreator} />
      {/* Content */}
      <div className="flex flex-col relative">
        {!isEdit ? (
          <>
            <div
              className="whitespace-pre-wrap px-1 break-all"
              data-testid={`${post.id}`}
            >
              {content}
            </div>
          </>
        ) : (
          <>
            <form
              className="w-[95%] mx-auto p-3 border border-indigo-600 border-solid rounded-md"
              onSubmit={updatePost}
            >
              <label className="block m-2 text-md font-semibold underline text-gray-700 mb-1">
                Content
              </label>
              <textarea
                onChange={(event) => setEditableContent(event.target.value)}
                className="whitespace-pre-wrap w-full py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={editableContent}
              />

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="m-2 py-1 px-3 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  Save
                </button>
              </div>
            </form>
          </>
        )}
      </div>
      <PostBottom
        changeEditStatus={changeEditStatus}
        isEdit={isEdit}
        post={post}
        isPostCreator={isPostCreator}
        commentIconBool={false}
        onDelete={onDelete}
        reply_page={true}
      />
      <div className="">
        <button
          onClick={() => handleReplyClick(item)}
          className="text-gray-500 hover:text-gray-700 font-bold absolute bottom-[9px] left-16 flex flex-col gap-y-[12px]"
        >
          {commentIcon}{" "}
          <span className="font-semibold text-sm text-black">
            {item.reply.comments?.length || 0} Comments
          </span>
        </button>
      </div>
    </div>
  );
};

export default AnswerItem;
