import React, { useState, useCallback, useContext } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "./firebase.js";

const PasswordReset = ({ userIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const handleResetPassword = useCallback(async () => {
    setError(null);
    setMessage(null);

    if (!email) {
      setError("Please enter a valid email.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset link sent to your email.");
    } catch (errorny) {
      setError(error.message);
    }
  }, [email]);

  return (
    <div className="flex flex-col gap-y-2 p-2">
      <h1 className="text-2xl font-bold mb-4">Reset Password</h1>

      {!userIsLoggedIn && (
        <div className="email-input">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter your email"
          />
        </div>
      )}

      <button
        onClick={handleResetPassword}
        className="w-full bg-gray-600 text-white py-3 rounded-lg font-semibold hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
      >
        Send Reset Instructions
      </button>

      {message && <p className="text-green-500 mt-2">{message}</p>}
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default PasswordReset;
