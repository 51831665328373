import React, { useState } from "react";
import Leftsidebar from "./Sidebar/Sidebar.js";
import PostsContainer from "./Posts/PostsContainer.js";
import ContactUs from "../components/ContactUs/ContactUs.js";
import { useCategory } from "../utils/CategoryContext.js";
const Home = () => {
  const { category, setCategory } = useCategory();

  // Can Conditional render based upon other menu items - Post, Support, ETC
  const renderContent = () => {
    switch (category) {
      case "ContactUs":
        return <ContactUs />;

      default:
        return (
          <PostsContainer
            category={category}
            className="w-full max-w-xl mx-auto p-4 box-border w"
          />
        );
    }
  };

  return (
    <div className="flex flex-wrap flex-row w-full h-full">
      <div className="flex flex-row w-full bg-gray-100">
        <Leftsidebar setCategory={setCategory} />

        {renderContent()}
      </div>
    </div>
  );
};

export default Home;
