import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "./firebase.js"

const LoginComponent = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password).then(() => {
            navigate('/')
        }).catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorMessage, errorCode)
          alert("Try again", errorMessage)
        });
    };

    return (
        <main className='login'>
            <h1 className='text-2xl font-bold mb-4'>Log In</h1>
            <form className='loginForm' onSubmit={handleSubmit}>
                <label htmlFor='email'>Company or University Email Address</label>
                <input
                    type='text'
                    name='email'
                    id='email'
                    placeholder=""
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <label htmlFor='password'>Password</label>
                <input
                    type='password'
                    name='password'
                    id='password'
                    placeholder=""
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-3 mb-6 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button className='w-full bg-gray-600 text-white py-3 rounded-lg font-semibold hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500'>SIGN IN</button>
            </form>
        </main>
    );
};
export default LoginComponent;










// import Cookies from "universal-cookie";
// const cookies = new Cookies();
// const handleSubmit = (e) => {
//     e.preventDefault();
//     fetch("http://localhost:4000/auth/login", {
//       method: "POST",
//       body: JSON.stringify({
//         email,
//         password,
//       }),
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//     .then(res => res.json())
//     .then((data) => {
//         console.log(data)
//         if (data.success) {
//             cookies.set('token', JSON.stringify(data.token), { path: '/' }); 
//             navigate('/')

//         } else {
//             alert("Invalid credentials")
//         }
//     })
//     .catch((err) => console.error(err));
// };
