import React, { useState, useEffect, useCallback } from "react";
import { Popconfirm } from "antd";
import CommentForm from "./CommentForm";
import UserDetailsComponent from "../../Users/UserDetailsComponent";
import { commentIcon } from "../../SvgIcons";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import { Timestamp, setDoc, doc } from "firebase/firestore";
import { db } from "../../Auth/firebase.js";

const Comment = ({
  setComments,
  comment,
  user,
  handleDeleteComment,
  handleAddComment,
  formatTimestamp,
}) => {
  const [isReplying, setIsReplying] = useState(false);
  const toggleReplyForm = () => setIsReplying(!isReplying);

  const [editCommentId, setEditCommentId] = useState(null);
  const [editableContent, setEditableContent] = useState("");

  const [editState, setEditState] = useState({});

  // keeps track of what comment message and ID
  const handleCommentChange = (commentId, currentContent) => {
    // User clicks and turns off edited
    if (editCommentId === commentId) {
      setEditCommentId(null);
      setEditableContent("");
      setEditState((prevState) => ({
        ...prevState,
        [commentId]: false,
      }));
    } else {
      // User clicks edit on particular Post. Updates the states for information of that post

      setEditCommentId(commentId);
      setEditableContent(currentContent);
      setEditState((prevState) => ({
        ...prevState,
        [commentId]: true,
      }));
    }
  };

  const handleSubmitEdit = async (commentId, content, parent) => {
    try {
      // Creating a new updated Doc
      const updatedDoc = {
        content: content,
        parent: parent,
        timestamp: Timestamp.now(),
        uid: user.uid,
      };

      // Finding the DB entry where I will be updating
      const postRef = doc(db, "posts", commentId);

      // Updating Doc
      await setDoc(postRef, updatedDoc, { merge: true });

      // updates the local state? Alternative is making DB call but too much work on db
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === commentId ? { ...comment, content: content } : comment
        )
      );

      setEditCommentId(null);
      setEditableContent("");
      setEditState((prevState) => ({
        ...prevState,
        [commentId]: false,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="mb-4 p-y border-t rounded-lg relative ">
      <UserDetailsComponent postCreator={comment.creator} />

      <div className="pl-3">
        {user && user.uid === comment.uid && (
          <>
            {editState[comment.id] ? (
              <form
                data-testid="comment-textarea"
                className="w-[95%] mx-auto p-3 border border-indigo-600 border-solid rounded-md"
                onSubmit={(e) => {
                  e.preventDefault();

                  handleSubmitEdit(comment.id, editableContent, comment.parent);
                }}
              >
                <label className="block m-2 text-md font-semibold underline text-gray-700 mb-1">
                  Content
                </label>
                <textarea
                  onChange={(e) => setEditableContent(e.target.value)}
                  className="whitespace-pre-wrap w-full py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={editableContent}
                />

                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="m-2 py-1 px-3 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                  >
                    Save
                  </button>
                </div>
              </form>
            ) : (
              <p className="mr-2 text-gray-800" data-testid="comment-content">
                {comment.content}
              </p>
            )}
          </>
        )}

        <div className="flex justify-between mt-2 text-sm">
          <button
            onClick={toggleReplyForm}
            className="text-blue-500 hover:underline"
          >
            {commentIcon}
          </button>
          {user?.uid === comment.uid && (
            <div className="flex space-x-2">
              <button
                data-testid="comment-edit-button"
                onClick={() => handleCommentChange(comment.id, comment.content)}
              >
                {editState[comment.id] ? (
                  <Tooltip title="Cancel Edit">
                    <CloseIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit Post">
                    <EditIcon />
                  </Tooltip>
                )}
              </button>
              <Popconfirm
                title="Are you sure you want to delete this comment?"
                onConfirm={() => handleDeleteComment(comment.id)}
                okText="Yes"
                cancelText="No"
              >
                <button className="hover:underline">
                  <DeleteOutlineIcon />
                </button>
              </Popconfirm>
            </div>
          )}
        </div>
      </div>

      {isReplying && (
        <div className="ml-8 mt-6">
          <CommentForm
            handleAddComment={handleAddComment}
            parentCommentId={comment.id}
            user={user}
            onCancel={toggleReplyForm}
          />
        </div>
      )}

      {comment.replies && comment.replies.length > 0 && (
        <div className="ml-4 mt-4 border-l-2 pl-4">
          {comment.replies.map((reply) => (
            <Comment
              key={reply.id}
              comment={reply}
              user={user}
              handleDeleteComment={handleDeleteComment}
              handleAddComment={handleAddComment}
              formatTimestamp={formatTimestamp}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Comment;
